<template>
  <div
    class="rec-chip-wrapper u-display-flex u-flex-wrap-yes u-height-100 u-width-100 u-flex-align-items-center u-flex-justify-content-center"
  >
    <span class="tag-token rec-chip u-font-size-7 u-font-weight-400">
      {{ computedLabelChip }}
    </span>
  </div>
</template>

<script>
import { formatter } from '../../../utils/helpers/formatter';

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'recChip',
  props: {
    chipText: {
      type: String,
      default: ''
    },
    formatterMap: {
      type: Object,
      default() {
        return {
          bid: 'currency'
        };
      }
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    },
    isComputedChip: {
      type: Boolean,
      default: false
    },
    recTypeKey: {
      type: String,
      default: ''
    },
    labelKey: {
      type: String,
      default: 'rec_action'
    }
  },
  computed: {
    computedLabelChip() {
      if (this.isComputedChip && this.data?.length) {
        const rec = this.data.find((item) =>
          Object.values(item).includes(this.recTypeKey)
        );
        return rec ? this.formattedData(rec) : '';
      } else {
        return this.chipText;
      }
    }
  },
  methods: {
    // to format numbers appearing in chip text
    formattedData(recType) {
      const val = recType[this.labelKey];
      let numberFormatter = '';
      for (const recKey in Object.keys(recType)) {
        if (numberFormatter) break;
        for (const formatKey of Object.keys(this.formatterMap)) {
          if (recKey?.toLowerCase()?.includes?.(formatKey?.toLowerCase())) {
            numberFormatter = this.formatterMap[formatKey];
            break;
          }
        }
      }
      if (!numberFormatter) {
        numberFormatter = 'currency';
      }
      const splitString = val?.split(' ');
      if (splitString.length > 1) {
        return splitString
          .map((str) => {
            const res = !Number.isNaN(Number(str))
              ? formatter(str, numberFormatter)
              : str;
            return res;
          })
          .join(' ');
      } else {
        return val;
      }
    }
  }
};
</script>

<style lang="css" scoped>
.tag-token {
  padding: 0.4rem 0.8rem;
  border-radius: 2.6rem;
  background-color: #ffa800;
  border: solid 1px #ffffff;
  color: #ffffff;
}
.tag-token:hover {
  background-color: #dd9305;
}
.tag-token.rec-chip {
  padding: 0.4rem 0.8rem;
  border-radius: 2.6rem;
  background-color: rgba(139, 143, 147, 0.1);
  color: #2b333b;
}
.rec-chip-wrapper {
  margin-top: -1rem;
}
</style>
